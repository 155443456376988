import { SanityDocument, defineField, defineType } from "sanity";

const validateIcon = (parent: SanityDocument) => {
  const allIcons = ["normalIcon", "icon", "customIcon"];
  const iconsWithValue = allIcons.filter((k) => !!parent[k]);

  if (iconsWithValue?.length != 1)
    return "Either Colored Icon, Normal Icon or Custom Icon must have a value";

  return true;
};

export default defineType({
  name: "milestoneItem",
  type: "object",
  title: "Milestone Item",
  fields: [
    defineField({
      name: "icon",
      title: "Colored Icon",
      type: "coloredIcon",
      validation: (Rule) =>
        Rule.custom<string>((field, context) => {
          return validateIcon(context?.parent as SanityDocument);
        })
    }),
    defineField({
      name: "normalIcon",
      title: "Normal Icon",
      type: "designSystemIcon",
      validation: (Rule) =>
        Rule.custom<string>((field, context) => {
          return validateIcon(context?.parent as SanityDocument);
        })
    }),
    {
      name: "customIcon",
      title: "Custom Icon (SVG)",
      type: "image",
      options: {
        accept: "image/svg+xml"
      },
      validation: (Rule) =>
        Rule.custom<string>((field, context) => {
          return validateIcon(context?.parent as SanityDocument);
        })
    },
    {
      name: "title",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "description",
      type: "multilingualText",
      validation: (Rule) => Rule.required()
    }
  ],
  preview: {
    select: {
      title: "title.en",
      media: "image"
    },
    prepare({ title, media }) {
      return {
        title,
        media
      };
    }
  }
});
