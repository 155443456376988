import { GiPathDistance } from "react-icons/gi";
import { defineField, defineType } from "sanity";

export default defineType({
  name: "specialVoyageSegment",
  title: "Special Voyage Segment",
  type: "object",
  icon: GiPathDistance,
  preview: {
    select: {
      title: "cruise.data.cruiseCode",
      media: "map"
    }
  },
  fields: [
    defineField({
      name: "cruise",
      title: "Cruise",
      type: "reference",
      to: [{ type: "cruise" }],
      options: {
        filter: (context: any) => ({
          filter: `_type == "cruise" && _id in (*[_type=="specialVoyageData" && _id == $specialVoyageDataId][0].cruises[]._ref)`,
          params: { specialVoyageDataId: context.document.data?._ref }
        })
      }
    }),
    {
      name: "map",
      title: "Map Image",
      type: "image"
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString"
    }
  ]
});
