import { Stack } from "@sanity/ui";
import { CruiseDiagnosticImporterLog } from "@silversea-ssc/dotcom-api-types/CruiseDiagnosticImporterLog";
import SectionLabel from "../commons/SectionLabel";
import CruiseDiagnosticImporterLogTable from "./CruiseDiagnosticImporterLogTable";

type Props = {
  logs: readonly CruiseDiagnosticImporterLog[] | undefined;
};
const CruiseDiagnosticImporterLogs: React.FC<Props> = ({ logs }) => {
  if (!logs) return null;

  return (
    <Stack padding={4} space={[3, 3, 4, 5]}>
      <SectionLabel size={4} align="left">
        Cruise Import Logs
      </SectionLabel>
      <CruiseDiagnosticImporterLogTable logs={logs} />
    </Stack>
  );
};

export default CruiseDiagnosticImporterLogs;
